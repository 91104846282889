import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import AppbarNoAuth from '../AppBars/AppBarNoAuth';
import { fetchUser } from '../../store/actions/useractions'
import { toast } from 'react-toastify';

export default function TestGetUser() {
    const [id, setId] = useState(null)
    const [user, setUser] = useState({})
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!id)
            return;
        fetchUser(id)
            .then(user => setUser(user))
            .catch(err => {
                toast.error('Error!')
                console.log(err)
            })
    }
    return (
        <>
            <AppbarNoAuth />
            <div className="container" style={{ marginTop: "20%" }}>
                <h3 className="text-center">Get User Demo</h3>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <input className="form-control" name="ID" onChange={(e) => setId(e.target.value)} placeholder="UserID" />
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <input type="submit" className="btn btn-dark sign-up" placeholder="Pull Data" />
                        </div>
                    </div>
                </form>
                <div className="row mb-2 d-flex justify-content-center align-content-center">
                    <div className="col-4">
                        <table>
                            <tbody>
                                {Object.keys(user).map((item, index) => (
                                    <tr>
                                        <td>{item}</td>
                                        <td>{user[item]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
} 