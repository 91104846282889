import client from '../../utils/client';
import { store } from '../index';
import { START_LOADING, STOP_LOADING, SIGNUP_STEP1_SAVE, SIGNUP_STEP2_SAVE, LOGGED_OUT } from '../types';
import Axios from 'axios';
import urls from '../../utils/urls';
import axios from 'axios';
import { toLower } from 'lodash';

const baseUrl = process.env.REACT_APP_REALTOUR_API_URL
/**
 * Create A User or Register
 * @param {*} data Object Containing User Info 
 */
export const createUser = (data) => {
    store.dispatch({ type: START_LOADING })
    return new Promise((resolve, reject) => {
        client.post(`/api/createUser`, data)
            .then(res => {
                console.log(res.data)
                store.dispatch({ type: STOP_LOADING })
                store.dispatch({
                    type: SIGNUP_STEP1_SAVE,
                    payload: { ...res.data, password: data.password }
                })
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
                store.dispatch({ type: STOP_LOADING })
                reject(err)
            })
    })
}

export const fetchUser = (id, Token) => {
    return new Promise((resolve, reject) => {
        client.get(
            `/api/GetUser?id=${id}`,
            {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            }
        )
            .then(res => {
                console.log(res.data)
                resolve(res.data)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
    })
}

/**
 * Check for valid Locense
 * @param {*} license 
 * @param {*} userId 
 * @param {*} Token 
 * @param {*} firstName 
 * @param {*} lastName 
 */
export const checkValidLicense = (license, userId, Token, firstName, lastName, jurisdiction, purposes) => {
    store.dispatch({ type: START_LOADING })
    return new Promise((resolve, reject) => {
        Axios.post(`${baseUrl}/api/CheckLicense`,
            {
                licenseNumber: license,
                firstName: firstName,
                lastName: lastName,
                jurisdiction: jurisdiction,
                userId: userId,
                purposes
            },
            {
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            })
            .then(response => {
                store.dispatch({ type: STOP_LOADING })
                resolve(response)
            })
            .catch(err => {
                store.dispatch({ type: STOP_LOADING })
                reject(err)
                console.log(err)
            })
    })
}

export const verifyPhone = (phone, userId, token) => {
    store.dispatch({ type: START_LOADING })
    console.log("token", token)
    return new Promise((resolve, reject) => {
        Axios.post(
            baseUrl + '/api/SendPhoneVerificationCode',
            {
                phoneNumber: phone,
                userId: userId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
            .then(response => {
                store.dispatch({ type: STOP_LOADING })
                store.dispatch({ type: SIGNUP_STEP2_SAVE, payload: { phoneNumber: phone } })
                resolve(response.data)
            })
            .catch(err => {
                store.dispatch({ type: STOP_LOADING })
                reject(err)
            })
    })
}


export const verifyCode = (code, userId, token) => {
    store.dispatch({ type: START_LOADING })
    return new Promise((resolve, reject) => {
        Axios.post(
            baseUrl + '/api/ValidatePhoneVerificationCode',
            {
                code: code,
                userId: userId
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
            .finally(() => {
                store.dispatch({ type: STOP_LOADING })
            })
    })
}

export const authenticate = (email, password) => {
    return new Promise((resolve, reject) => {
        axios.post(
            baseUrl + '/api/Authenticate',
            {
                emailAddress: email ? toLower(email) : '',
                Password: password
            }
        )
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const logoutUser = () => {
    store.dispatch({ type: LOGGED_OUT })
    localStorage.removeItem("realtourAuth")
}

export const contactUs = (request) => {
    return new Promise((resolve, reject) => {
        client.post(
            '/api/ContactUs',
            request
        )
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const verifyEmail = (code, id) => {
    return new Promise((resolve, reject) => {
        client.post(
            '/api/verifyemail?code=' + code + '&Id=' + id,
            {
                code: code,
                Id: id
            }
        )
            .then(response => {
                resolve(response.data)
            })
            .catch(err => {
                reject(err)
                console.log(err)
            })
    })
}

export const UpdateProfilePicture = (file, userId) => {
    let form = new FormData()
    form.append("Photo", file)
    return new Promise((resolve, reject) => {
        client.post(
            `/api/UploadProfileImage?id=${userId}`,
            form,
            {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            }
        )
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const UpdateServiceArea = (serviceAreas) => {
    return new Promise((resolve, reject) => {
        client.post(
            `${urls.updateServiceArea}`,
            serviceAreas
        )
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const UpdateUser = (data) => {
    return new Promise((resolve, reject) => {
        client.post(
            `${urls.updateUser}`,
            data
        )
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const ForgotPassword = (email) => {
    return new Promise((resolve, reject) => {
        client.post(
            `${urls.forgotPassword}`,
            { accountEmailAddress: email }
        )
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const UpdatePassword = (token, password) => {
    return new Promise((resolve, reject) => {
        client.post(
            `${urls.updatePassword}`,
            {
                Password: password,
                Token: token
            }
        )
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export const GetUserReviews = (id) => {
    return client.post(`${urls.getUserReviews}?userId=${id}`)
}

export const GetPublicUserProfile = (id) => {
    return client.get(`${urls.publicProfile}?id=${id}`)
}

export const AcceptTermsAndConditions = (token) => {
    return Axios.get(`${baseUrl}${urls.acceptTermsAndCond}`,
        {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
}

export const UpdateUserLanguages = (langs) => {
    return client.post(`${urls.updateLanguage}`, { languages: langs })
}

export const DeleteAccount = () => {
    return client.post((`${urls.deleteAccount}`))
}
