// Mandatory imports
import React, { Fragment, Suspense } from "react";
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './css/styles.css';
import './customCss/style.css';
import './customCss/side-nav.css';
import './customCss/account.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { AppContextProvider } from "./Contexts/AppContext";
import TestComponent from './components/TestComponent';
import { Container, createMuiTheme, ThemeProvider } from "@material-ui/core";
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import MainLoading from "./components/MainLoading";
import Toaster from "./components/Toaster";
import { ConfirmProvider } from "./Contexts/ConfirmContext";
import { HelmetProvider } from "react-helmet-async";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF3D00',
      dark: '#BE2E00',
      light: '#FF3D00'
    }
  },
  typography: {
    button: {
      fontSize: "1.1rem",
      fontWeight: 400
    }
  }
});

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: false,
      loading: true
    }
  }
  
  //   const [isNotAuthenticated , setIsNotAuthenticated] = useState(true)
  //  if(localStorage.getItem("authAccess")){

  //  }
  render() {

    return (
      <div className="App">
        <Container maxWidth="xl" style={{ padding: 0 }}>
          <HelmetProvider>
            <ThemeProvider theme={theme}>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <MainLoading />
                  <Toaster />
                  <AppContextProvider>
                    <ConfirmProvider>
                      <Fragment>
                        <Suspense fallback={null}>
                          <BrowserRouter>
                            <TestComponent />
                            <Fragment>
                              <Router />
                            </Fragment>
                          </BrowserRouter>
                        </Suspense>
                      </Fragment>
                    </ConfirmProvider>
                  </AppContextProvider>
                </PersistGate>
              </Provider>
            </ThemeProvider>
          </HelmetProvider>
        </Container>
      </div>
    )
  }
}

export default App;
