export const ProjectRoutes = [
  {
    path: "/home",
    component: "HomeComponent",
    isPrivate: false,
    componentPath: "/Pages/Home",
  },
  {
    path: "/agents/:id",
    component: "HomeComponent",
    isPrivate: false,
    componentPath: "/Pages/AgentProfile",
  },
  {
    path: "/policy",
    component: "policy",
    componentPath: "/Pages/Policy",
    isPrivate: false,
  },
  {
    path: "/accept-terms",
    component: "AcceptTerms",
    componentPath: "/Pages/AcceptTerms",
    isPrivate: false,
  },
  {
    path: "/terms-of-service",
    component: "TermsOfService",
    componentPath: "/Pages/TermsOfService",
    isPrivate: false,
  },
  {
    path: "/privacy-policy",
    component: "PrivacyPolicy",
    componentPath: "/Pages/PrivacyPolicy",
    isPrivate: false,
  },
  // {
  //   path: "/tour",
  //   component: "TourComponent",
  //   isPrivate: true,
  //   componentPath: "/TourComponent",
  // },
  {
    path: "/phoneResetPassword",
    componentPath: "/forgot password/ResetPasswordPhone",
    isPrivate: false,
    component: "ResetPasswordPhone",
  },
  //   <Route path="/confirmationByPhone" component={fpPhoneverification}></Route>
  {
    path: "/confirmationByPhone",
    component: "fpPhoneverification",
    isPrivate: false,
    componentPath: "/forgot password/fpPhoneverification",
  },
  {
    path: "/user-type",
    component: "CheckUserType",
    isPrivate: false,
    componentPath: "/User/CheckUserType",
  },
  {
    path: "/credentials",
    component: "LicenseNoAndData",
    isPrivate: false,
    componentPath: "/User/LicenseVerification",
  },
  {
    path: "/phoneVerification",
    component: "phoneVerifyForSignUp",
    isPrivate: false,
    componentPath: "/User/VerifyPhone",
  },
  {
    path: "/verifyEmail",
    component: "VerifyEmailForSignUp",
    isPrivate: false,
    componentPath: "/verifyEmailForSignUp",
  },
  {
    path: "/check-invite",
    component: "CheckInvite",
    isPrivate: false,
    componentPath: "/Invite/CheckInvite.js",
  },
  {
    path: "/request-invite",
    component: "RequestInvite",
    isPrivate: false,
    componentPath: "/Invite/RequestInvite.js",
  },
  {
    path: "/register",
    component: "Register",
    isPrivate: false,
    componentPath: "/User/Register.js",
  },
  {
    path: "/verifyPhone",
    component: "VerificationCodePhone",
    isPrivate: false,
    componentPath: "/User/VerifyOTP",
  },
  {
    path: "/login",
    component: "LoginComponent",
    isPrivate: false,
    componentPath: "/User/Login",
  },
  // {
  //   path: "/forgotPassword",
  //   component: "ForgotPassword",
  //   isPrivate: false,
  //   componentPath: "/forgot password/ForgotPassword",
  // },
  // {
  //   path: "/accountRecovery",
  //   component: "forgotPassAccountRecovery",
  //   isPrivate: false,
  //   componentPath: "/forgot password/forgotPassAccountRecovery",
  // },
  {
    path: "/account-recovery",
    component: "AccountRecovery",
    isPrivate: false,
    componentPath: "/User/AccountRecovery",
  },
  {
    path: "/onboarding/service-areas",
    component: "OnboardingServiceArea",
    isPrivate: false,
    componentPath: "/User/ServiceArea"
  },
  {
    path: "/onboarding/payment-info",
    component: "OnboardingPaymentInfo",
    isPrivate: false,
    componentPath: "/User/PaymentInfo"
  },
  {
    path: "/forgot-password",
    component: "UpdatePassword",
    isPrivate: false,
    componentPath: "/User/UpdatePassword",
  },
  {
    path: "/check-email",
    component: "ResetSent",
    isPrivate: false,
    componentPath: "/User/ResetEmailSent",
  },
  {
    path: "/password-updated",
    component: "ResetSent",
    isPrivate: false,
    componentPath: "/User/PasswordUpdated",
  },
  {
    path: "/confirmation",
    component: "ConfirmEmail",
    isPrivate: false,
    componentPath: "/forgot password/passResetConfirmationMsg",
  },
  {
    path: "/verify_email",
    component: "VeryfyMail",
    isPrivate: false,
    componentPath: "/User/VerifyEmail",
  },
  {
    path: "/clients",
    component: "Clients",
    isPrivate: true,
    componentPath: "/clients/Clients",
  },
  {
    path: "/messages",
    component: "Messages",
    isPrivate: true,
    componentPath: "/messaging/messages",
  },
  {
    path: "/messages/:conversationId",
    component: "Messages",
    isPrivate: true,
    componentPath: "/messaging/messages",
  },
  // {
  //   path: "/resetPassword",
  //   component: "ResetPasswordComponent",
  //   isPrivate: false,
  //   componentPath: "/forgot password/ResetPasswordComponent",
  // },
  // {
  //   path: "/verificationCode",
  //   component: "FpVerificationCodePhone",
  //   isPrivate: false,
  //   componentPath: "/forgot password/fpVerificationCodePhone",
  // },
  {
    path: "/agentProfile",
    component: "ProfileComponent",
    isPrivate: true,
    componentPath: "/Account/AgentProfile",
  },
  {
    path: "/liveAppointment",// We have to remove later, kept for TEST
    component: "SuccessfulLiveAppointment",
    isPrivate: true,
    componentPath: "/Appointment/SuccessfulLiveAppointment",
  },
  {
    path: "/appt/accepted",
    component: "AppointmentAccepted",
    isPrivate: true,
    componentPath: "/Appointment/AppointmentAccepted",
  },
  {
    path: "/payment/success",
    component: "AppointmentLive",
    isPrivate: true,
    componentPath: "/Appointment/Create/AppointmentLive",
  },
  {
    path: "/payment/failure",
    component: "PaymentFailure",
    isPrivate: true,
    componentPath: "/Pages/PaymentFailure",
  },
  {
    path: "/payOption",
    component: "PaymentOption",
    isPrivate: true,
    componentPath: "/agentProfile/stepsToMakeAppointmentLive/paymentOption",
  },
  {
    path: "/cancelAppointment/:id",
    component: "CancelAppointment",
    isPrivate: true,
    componentPath: "/Appointment/CancelAppointment"
  },
  {
    path: "/cancelConfirmation",
    component: "CancelConfirmation",
    isPrivate: true,
    componentPath: "/Appointment/CancelConfirmation",
  },
  {
    path: "/appointment-submitted",
    component: "AppointmentCompletionApproved",
    isPrivate: true,
    componentPath: "/Appointment/AppointmentSubmitted"
  },
  {
    path: "/appointment-approved",
    component: "AppointmentCompletionApproved",
    isPrivate: true,
    componentPath: "/Appointment/AppointmentCompletionApproved"
  },
  {
    path: "/dashboardForm",
    component: "stepsToMakeAppointment",
    isPrivate: true,
    componentPath: "/Appointment/Create"
    //componentPath: "/Appointment/stepsToMakeAppointment",
  },
  {
    path: "/appointments/:appointment_id/proposals/:proposal_id",
    component: "AccpetProposal",
    isPrivate: true,
    componentPath: "/Appointment/AcceptProposal"
  },
  // {
  //   path: "/dashboardForm2",
  //   component: "stepsToMakeAppointment",
  //   isPrivate: true,
  //   componentPath: "/Appointment/stepsToMakeAppointment",
  // },
  {
    path: "/myAccount",
    component: "MyAccount",
    isPrivate: true,
    componentPath: "/Account/MyAccount",
  },

  // {
  //   path: "/counterOfferRequest",
  //   component: "CounterOfferRequest",
  //   isPrivate: true,
  //   componentPath:
  //     "/agentProfile/dashboard/Search/counter offer/counterOfferRequest",
  // },
  // {
  //   path: "/editNumber",
  //   component: "EditNumber",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/account/editPhoneNo",
  // },
  // {
  //   path: "/googleMap",
  //   component: "Mapping",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/settings/mapping",
  // },
  // {
  //   path: "/verifyEditPhone",
  //   component: "VerifyCodePhoneEdit",
  //   isPrivate: true,
  //   componentPath:
  //     "/agentProfile/dashboard/my account/account/verifyCodePhoneEdit",
  // },
  // {
  //   path: "/notification",
  //   component: "Notifications",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/settings/notifications",
  // },
  // {
  //   path: "/serviceAreas",
  //   component: "ServiceAreas",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/settings/serviceAreas",
  // },
  // {
  //   path: "/preferredAgents",
  //   component: "PreferredAgents",
  //   isPrivate: true,
  //   componentPath:
  //     "/agentProfile/dashboard/my account/settings/preferredAgents",
  // },
  // {
  //   path: "/demoAgent",
  //   component: "Demopreferred",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/settings/demopreferred",
  // },
  // {
  //   path: "/blockedAgents",
  //   component: "BlockedAgents",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/my account/settings/blockedAgents",
  // },
  // {
  //   path: "/paymentInformation",
  //   component: "PaymentInfo",
  //   isPrivate: true,
  //   componentPath:
  //     "/agentProfile/dashboard/my account/payment information/paymentInfo",
  // },
  // {
  //   path: "/editPaymentDetails",
  //   component: "PaymentEdit",
  //   isPrivate: true,
  //   componentPath:
  //     "/agentProfile/dashboard/my account/payment information/paymentEdit",
  // },
  // {
  //   path: "/agentAppointment",
  //   component: "MyAppointment",
  //   isPrivate: true,
  //   componentPath: "/Appointment/Listing/myAppointment"
  //   //  "/agentProfile/dashboard/appointments/myAppointment/myAppointment",
  // },
  {
    path: "/agentAppointment/:id",
    component: "MyAppointment",
    isPrivate: true,
    componentPath: "/Appointment/Listing/myAppointment"
    //  "/agentProfile/dashboard/appointments/myAppointment/myAppointment",
  },
  {
    path: "/appointments/:id/edit",
    component: "EditAppointment",
    isPrivate: true,
    componentPath: "/Appointment/Edit/index"
    // componentPath: "/agentProfile/dashboard/appointments/editForm",
  },
  {
    path: "/appointments/:id/complete",
    component: "SubmitAppointment",
    isPrivate: true,
    componentPath: "/Appointment/Submit/index"
    // componentPath: "/agentProfile/dashboard/appointments/editForm",
  },
  {
    path: "/appointments/:id/approve",
    component: "ApproveAppointment",
    isPrivate: true,
    componentPath: "/Appointment/Approve/index"
    // componentPath: "/agentProfile/dashboard/appointments/editForm",
  },
  {
    path: "/searchApt",
    component: "Search",
    isPrivate: true,
    componentPath: "/Appointment/Search/SearchAppointment"
    //componentPath: "/agentProfile/dashboard/Search/search",
  },
  // {
  //   path: "/filter",
  //   component: "Filter",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/Search/filter",
  // },
  // {
  //   path: "/messages",
  //   component: "Messages",
  //   isPrivate: true,
  //   componentPath: "/agentProfile/dashboard/messages/messages",
  // },
  // {
  //   path: "/clientProfile",
  //   component: "ClientProfile",
  //   isPrivate: true,
  //   componentPath: "/clientProfile/clientProfile",
  // },
  // {
  //   path: "/myAppointments",
  //   component: "Appointment",
  //   isPrivate: true,
  //   componentPath: "/clientProfile/appointment",
  // },
  // {
  //   path: "/clientAppointments",
  //   component: "clientAppointments",
  //   isPrivate: true,
  //   componentPath: "/clientProfile/appointments/cancelByClientAppointment",
  // }
];
export default ProjectRoutes;
