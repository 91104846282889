import { createContext, useContext, useState } from "react";

export const ConfirmContext = createContext({
    confirm: false,
    setConfirm: () => { }
})

export const useConfirm = () => useContext(ConfirmContext)

export const ConfirmProvider = ({ children }) => {
    const [confirm, setConfirm] = useState(false)

    return (
        <ConfirmContext.Provider
            value={{
                confirm: confirm,
                setConfirm: setConfirm
            }}
        >
            {children}
        </ConfirmContext.Provider>
    )
}
