import { store } from '../index'
import { SHOW_NOTIFICATION, START_LOADING, STOP_LOADING, TOGGLE_DRAWER } from '../types'

export const showNotifications = (notification) => {
    store.dispatch({ type: SHOW_NOTIFICATION, payload: notification })
}

export const toggleDrawer = () => {
    store.dispatch({ type: TOGGLE_DRAWER })
}

export const startLoading = () => {
    store.dispatch({ type: START_LOADING })
}

export const stopLoading = () => {
    store.dispatch({ type: STOP_LOADING });
}