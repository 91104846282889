import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../../store/actions/useractions';
import { showNotifications } from '../../store/actions/uiactions';
import { START_LOADING, STOP_LOADING } from '../../store/types';
import AppbarNoAuth from '../../components/AppBars/AppBarNoAuth';
import logo from "../../images/RTH_LOGO.png";
import { Typography } from '@material-ui/core';
import vantage_logo from '../../images/vantage_logo_new.png';
import { MapRounded } from '@material-ui/icons';

export default function HomeComponent() {
  let history = useHistory();
  let { auth, user } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    email: "", subject: "", message: ""
  });
  const { email, subject, message } = values
  const handleChange = event => {
    const { name, value } = event.target
    setValues({
      ...values,
      [name]: value
    })

  }
  useEffect(() => {
    if (auth)
      history.push('/agentProfile')
  }, [])
  // const signIn = () =>{
  //   history.push('/login')

  // }
  const [error, setError] = useState({});
  const sendEmailToRealTour = event => {
    event.preventDefault();
    const error = {};
    if (!email) {
      error.empty = "Enter email address!"
    }
    else if (!/^(.+)@(.+)$/.test(values.emailAdd)) {
      error.valid = "Enter a valid email address!"
    }
    dispatch({ type: START_LOADING })
    contactUs({
      email, message, subject
    }).then(res => {
      setValues({
        email: "",
        message: "",
        subject: ""
      })
      console.log(res.data)
      showNotifications({ type: 'success', message: 'Thank you! We\'ll contact you soon' });
    }).catch(err => {
      console.log(err)
      showNotifications({ type: 'error', message: 'There was some error! Please try again' });
    })
      .finally(() => {
        dispatch({ type: STOP_LOADING })
      })
  }

  return (
    <>
      <Helmet>
        <title>Real Tour - Handle more appointments & be more productive</title>
      </Helmet>
      <AppbarNoAuth />
      {/* <header id="header" className="fixed-top" style={{ height: '90px' }}>
        <div className="container-fluid">
          <nav className="navbar-custom " style={{ display: 'flex', marginRight: 'auto', padding: '0px' }}>
            <div className="logo navbar-brand ">
              <a href="/"><img src="./images/logo1.png" alt="" width="180" height="34" className="img-fluid" /></a>
            </div>
            <div className="mob-btn ml-auto ">
              <Link to="/login" className='text-black'>
                <Button color="primary" style={{ fontWeight: 600, width: 116 }}>Sign&nbsp;In</Button>
              </Link>
            </div>
            <div className="main-nav d-none d-lg-block">
              <a className="mobile-logo" href="/"><img src="./images/mobile-logo.png" alt="" width="28" height="31" className="img-fluid" /></a>
              <div className="nav-custom">
                <ul>
                  <li className="nav-item  "><a href="#video">How it works</a></li>
                </ul>
                <ul className="nav-btn  ml-auto mb-auto">
                  <li className=""><Link to="/user-type"><button type="button" className="btn btn-dark sign-up" style={{ width: '116px', border: 'none' }}>Sign up</button></Link></li>
                  <li className="">
                    <Link to="/login">
                      <button
                        type="button"
                        className="btn btn-outline-dark sign-in m-l-15"
                        style={{
                          width: '116px', border: "1px solid #cec9c9",
                          color: "#ff3d00", onMouseOver: "color : 'white'"
                        }}>Sign in</button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header> */}

      <section className="nero clearfix">
        <div className="container">
          <div className="nero-info"  >
            <h1>Handle more appointments & be more productive</h1>
            <p>A solution for ALL licensed agents. Increase your income and build customer satisfaction by working with licensed agents to carry out more appointments.</p>
            <div>
              <Link to='/user-type'> <button type="button" className="btn btn-dark " style={{ border: 'none' }}>create account</button></Link>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-Cont bg-grey" id="video">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="content"  >
                <h4 className="title">How It Works</h4>
                <p className="description">Watch how RealTour makes appointments a win-win.</p>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>get started</button></Link>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2" >
              <div className="video-wap">
                <iframe width="100%" height="280" title="Video - How it Works" src="https://www.youtube.com/embed/Hn49FyT4mQY" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-Cont">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-6" >
              <div className="video-wap">
                <iframe width="100%" height="280" title="Video - Idea behind Realtour" src="https://www.youtube.com/embed/eXLbrwT3A-Q" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>

            </div>

            <div className="col-lg-6">
              <div className="content"  >
                <h4 className="title">Busy Agents, There’s A Better Way</h4>
                <p className="description">Take a look at how we can help make life easier on you.</p>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>create account</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="sec-Cont bg-grey">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="content"  >
                <h4 className="title">Manage More Appointments</h4>
                <p className="description">Have another licensed professional carry out the appointment so you can focus on growing your business, giving yourself a break, or just spending more time with your loved ones.</p>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>start now</button></Link>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2" >
              <div className="img-wap">
                <img src="./images/card-1.png" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec-Cont">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-6" >
              <div className="img-wap">
                <img src="./images/card-2.png" className="img-fluid" alt="" />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="content">
                <h4 className="title">Having A Slow Month?</h4>
                <p className="description">We've all been there. During slow times, you can take advantage of carrying out appointments to supplement your income.</p>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>start earning money</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-Cont bg-grey">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="content"  >
                <h4 className="title">Total Control Over Your Schedule</h4>
                <p className="description">The more appointments you take, the faster you will learn. Did we mention you can supplement your income as well? Because you know....there's that too.</p>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>get started</button></Link>
              </div>
            </div>

            <div className="col-lg-6 order-lg-2" >
              <div className="img-wap">
                <img src="./images/card-3.png" className="img-fluid" alt="" />
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className="sec-Cont get-started">
        <div className="container" >
          <div className="row card-Cont">
            <div className="col-lg-12 mb-4">
              <div className="content"  >
                <h4 className="title">Ready To Get <span>Started? </span></h4>
                <Link to='/user-type'><button type="button" className="btn btn-dark" style={{ border: 'none' }}>create account</button></Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sec-Cont bg-grey contact" id="contactform" >
        <div className="container">
          <div className="cont-form">
            <h3>Contact us</h3>
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <form onSubmit={sendEmailToRealTour} role="form" className="form">
                    <div className="form-group">
                      <input type="email" value={values.email ?? ''} className="form-control" name="email" id="email" placeholder="Email address" onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <input type="text" value={values.subject ?? ''} className="form-control" name="subject" id="subject" placeholder="Subject" onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                      <textarea className="form-control" value={values.message ?? ''} name="message" rows="5" placeholder="Message" onChange={handleChange} required></textarea>
                    </div>
                    <div className=""><button type="submit" className="btn btn-dark" title="Send Message" style={{ border: 'none' }}>send</button></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <ul className="list-unstyled">
                <li><a href="#video">How it works</a></li>
                <li><a href="#contactform" className="">Contact us</a></li>
                <li><a href="/terms-of-service" className="">Terms of Service</a></li>
              </ul>
            </div>
            <div className="col-lg-12"> <a href="/" className="foot-logo" > <img src={logo} height="28px" alt="" /> </a> </div>
            {/* <div className="col-lg-12">
              <a href="/" className="foot-logo" ><img src={logo} height="28px" alt="" /> </a>
              <Typography variant='caption' component={'span'} style={{ color: '#000' }} >Powered By</Typography>
              <a href="/" style={{ padding: "10px 0px 10px 0px", display: 'block'}} ><img src={vantage_logo} height="36px" alt="" /> </a>
              <Typography variant='body1' align='center' style={{color: 'gray', textAlign:"center", marginBottom: "18px"}}><MapRounded/> &nbsp; 16769 Bernardo Center Dr Ste #1-228, San Diego, CA 92128</Typography>
            </div> */}
            <div className="col-lg-12"> <div className="copyright">copyright:©  realtour {new Date().getFullYear()}. all rights reserved.</div></div>
          </div>
        </div>
      </footer>
      {/* <ScriptTag isHydrating={true} type="text/javascript" src="../js/jquery.min.js" />
          <ScriptTag isHydrating={true} type="text/javascript" src="../js/main.js" /> */}

    </>
  )
}

// How It Works:
// https://youtu.be/PI6NLv1XNwI
// Busy Agents There's a Better Way
// https://youtu.be/tAL0CqLb1Bg