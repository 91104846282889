import { uireducer as ui } from './reducers/uireducer'
import { authreducer as auth }  from './reducers/authreducer'
import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['ui']
  }
const rootReducer = combineReducers({
    ui,
    auth
}
)
const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)
const storeConfig = () => {
    return { store, persistor }
  }
export default storeConfig;
export {
    store,
    persistor
}