const urls = {
    login: '/auth/login',
    getUser: '/api/GetUser',
    checkLicense: '/api/CheckLicense',
    sendVerificationCode: '/api/SendPhoneVerificationCode',
    verifyPhoneCode: '/api/ValidatePhoneVerificationCode',
    sendVerificationEmail: "/api/ResendVerificationEmail",
    mlsSearch: '/api/SearchByMLSListing',
    forgotPassword: "/api/SendEmailVerificationCode",
    updatePassword: "/api/ForgotPassword",
    createAppointment: '/api/CreateAppointment',
    getExcludeAgentByName: '/api/GetExcludeAgentByName',
    getAgentAppointments: '/api/GetMyAppointment',
    cancelAppointment: '/api/CancelAppointment',
    searchAppointment: '/api/SearchAppointment',
    getSingleAppointment: '/api/GetAppointmentAndListingAgent',
    updateAppointment: '/api/UpdateAppointment',
    fetchTermsOfSevice: '/api/GetTermAndCondition',
    appointmentCompleted: '/api/AppointmentCompleted',
    approveAppointmentCompletion: '/api/ApproveAppointmentCompletion',
    fetchPrivacyPolicy: '/api/getPrivacyPolicy',
    takeAppointment: '/api/SaveProposals',
    updateServiceArea: '/api/UpdateUserServices',
    updateUser: '/api/UpdateUser',
    checkInvite: "/api/CheckInvite",
    requestInvite: "/api/RequestInvite",
    getUserReviews: "/api/GetUserReview",
    getProposals: "/api/GetProposals",
    acceptProposal: "/api/AcceptProposalAlt",
    publicProfile: "/api/GetPublicUserInfo",
    getProposalById: "/api/GetProposalById",
    acceptTermsAndCond: "/api/AcceptTermAndConditions",
    updateLanguage: "/api/UpdateUserLanguage",
    deleteAccount: "/api/DeleteAccount",
    inviteClient: "/api/InviteClient",
    fetchClients: "/api/FetchClients",
    fetchConversations:"/api/conversations",
    searchClients:"/api/SearchClients"
}
export default urls;