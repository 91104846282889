import React from 'react'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import AppBarNoAuth from './AppBars/AppBarNoAuth'
import AppbarInner from './AppBars/AppBarInner'
import AutoCompleteSearch from './Inputs/AutoCompleteSearch'
import TestSignup from './TestSignUp'
import TestGetUser from './Tests/TestGetUser';
import CheckUserType from '../containers/User/CheckUserType'
import { ToastContainer } from 'react-toastify'
import AppDrawer from './Drawer';
export default function TestComponent() {
    return (
        <>
            <ToastContainer />
            <Route path="/test/appbar-noauth">
                <AppBarNoAuth />
            </Route>
            <Route path="/test/appbar-inner">
                <AppbarInner />
            </Route>
            <Route path="/test/autocomplete">
                <AutoCompleteSearch />
            </Route>
            <Route path="/test/create-user">
                <TestSignup />
            </Route>
            <Route path="/test/get-user">
                <TestGetUser />
            </Route>
            <Route path="/test/checkusertype">
                <CheckUserType />
            </Route>
            <Route path="/test/drawer">
                <AppDrawer />
            </Route>
        </>
    )
}