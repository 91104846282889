import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import AppbarNoAuth from './AppBars/AppBarNoAuth';
import { createUser } from '../store/actions/useractions'
import { toast } from 'react-toastify';

export default function TestSignup() {
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        licensedAgent: null
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        createUser(data)
            .then(res => {
                console.log(res)
                toast.success('Created Successfully')
            })
            .catch(err => {
                toast.error('Some error Occured!')
            })
    }
    return (
        <>
            <AppbarNoAuth />
            <div className="container" style={{ marginTop: "20%" }}>
                <h3 className="text-center">Create User Demo</h3>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <input className="form-control" onChange={(e) => setData({ ...data, firstName: e.target.value })} name="firstName" placeholder="First name" />
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <input className="form-control" name="firstName" onChange={(e) => setData({ ...data, lastName: e.target.value })} placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <label>
                                <input type="checkbox" name="firstName" onChange={(e) => setData({ ...data, licensedAgent: e.target.checked ? true : false })} className="mr-4" placeholder="licensedAgent" />
                                <b>LicensedAgent?</b>
                            </label>
                        </div>
                    </div>
                    <div className="row mb-2 d-flex justify-content-center align-content-center">
                        <div className="col-4">
                            <input type="submit" className="btn btn-dark sign-up" placeholder="Submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
} 