import { SIGNUP_STEP1_SAVE, SIGNUP_STEP2_SAVE, SIGNUP_STEP3_SAVE, SIGNUP_USER_TYPE, LOGGED_IN, LOGGED_OUT, SET_USER } from "../types";

const INITIAL_STATE = {
    auth: false,
    user: {},
    signupData: {
        userType: '',
        step1Complete: false,
        step2Complete: false,
        step3Complete: false,
        step1: {

        },
        step2: {

        },
        step3: {

        }
    }
}
export const authreducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_TYPE:
            return { ...state, signupData: { ...state.signupData, userType: action.payload } }
            
        case SIGNUP_STEP1_SAVE:
            return { ...state, signupData: { ...state.signupData, step1: action.payload, step1Complete: true } }

        case SIGNUP_STEP2_SAVE:
            return { ...state, signupData: { ...state.signupData, step2: action.payload, step2Complete: true } }

        case SIGNUP_STEP3_SAVE:
            return { ...state, signupData: { ...state.signupData, step3: action.payload, step3Complete: true } }

        case LOGGED_IN:
            return { ...state, auth: true, token: action.payload.token, signalRToken: action.payload.signalRToken, user: action.payload.user }

        case SET_USER:
            return { ...state, auth: true, user: action.payload.user }

        case LOGGED_OUT:
            return INITIAL_STATE;
        default:
            return state;
    }
}