import axios from 'axios';
import { store } from '../store';
import { SHOW_NOTIFICATION } from '../store/types';
import { logoutUser } from '../store/actions/useractions';

const axiosClient = axios.create({
    baseURL: `${process.env.REACT_APP_REALTOUR_API_URL}`,
    headers: {
        'Accept': 'application/json'
    },
    // validateStatus: (status)=> {

    //   return (status>=200 && status<300) || status==422;
    // }
})
axiosClient.interceptors.request.use(function (request) {
    if (localStorage.getItem('realtourAuth')) {
        request.headers.Authorization = `Bearer ${localStorage.getItem('realtourAuth')}`
    }
    return request;
})

axiosClient.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (!error.response) {
        return alert("Network Error!");
    }
    if (error.response.status === 401) {
        console.log('not logged in')
        store.dispatch({ type: SHOW_NOTIFICATION, payload: { type: 'error', message: 'UnAuthorized!' } })
        debugger
        logoutUser()
        window.location.assign('/login')
    }
    if (error.response.status >= 500) {
        console.log(error.response.data)
        let message = 'Internal Server Error';
        console.log(message);
    }
    return Promise.reject(error);
})

export default axiosClient;