import { Link } from "react-router-dom"
import { useLayoutEffect, useRef, useState } from "react";
import '../../customCss/login.css';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles, Button } from "@material-ui/core";
import { SIGNUP_USER_TYPE } from "../../store/types";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../components/Logo";
import { Helmet } from "react-helmet-async";

export default function CheckUserType(props) {
    const type = useSelector(state => state.auth.signupData.userType)
    const [selectError, setselectError] = useState({});
    const [usertype, setUserType] = useState(type)
    const inviteEmailRef = useRef(null)
    const params = new URLSearchParams(props.location.search)
    let invitedEmail = params.get("email")
    const dispatch = useDispatch()
    let userTypes = ['agent', 'notagent']

    const handleInputChange = (e) => {
        setUserType(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!userTypes.includes(usertype))
            setselectError({ error: "Please select one of the options" })
        else {
            dispatch({ type: SIGNUP_USER_TYPE, payload: usertype })
            setselectError({})
            props.history.push({
                pathname: '/register',
                state: { email: inviteEmailRef.current }
            })
        }
    }

    useLayoutEffect(() => {
        console.log(props.location.state)
        if (params.get("userType") === "client") {
            dispatch({ type: SIGNUP_USER_TYPE, payload: "client" })
            inviteEmailRef.current = invitedEmail
            props.history.push({
                pathname: '/register',
                state: { email: invitedEmail }
            })
        }
        if ((!props.location.state || !props.location.state.email) && !invitedEmail)
            props.history.push("/check-invite")
        if (props.location.state && props.location.state.email)
            inviteEmailRef.current = props.location.state.email
        if (invitedEmail) {
            inviteEmailRef.current = invitedEmail
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>Are you a licensed Agent? | Realtour</title>
            </Helmet>
            <div className="form signup-bg" >
                <div className="cross">
                    <Button variant="text" size="large" onClick={() => props.history.goBack()} type="button" className="mobile-nav-toggle d-lg-none"><i className="fa fa-times fa-lg"></i></Button>
                </div>
                <form action="#" method="post">
                    <div className="logo logo-none">
                        <Link to="/">
                            <Logo height="22px" />
                        </Link>
                    </div>
                    <div className="form-content p-t-9">
                        <h4 className="modal-title">Are you a licensed agent?</h4>
                        {Object.keys(selectError).map((key) => {
                            return <div key={key} style={{ color: "red", fontSize: "11px", fontWeight: "bold" }}>{selectError[key]}</div>
                        })}
                        <div className="form-group radio-btn">
                            <label className="form-check-label font-weight-bold" htmlFor="radio1">
                                <input type="radio"
                                    className="form-check-input" id="radio1"
                                    checked={usertype == 'agent' ? true : false}
                                    onChange={handleInputChange} name="agentradio"
                                    value="agent" />
                                Yes, I’m a licensed agent
                            </label>
                        </div>
                        <div className="form-group radio-btn">
                            <label className="form-check-label font-weight-bold" htmlFor="radio2">
                                <input type="radio"
                                    className="form-check-input" id="radio2"
                                    checked={usertype == 'notagent' ? true : false}
                                    onChange={handleInputChange} name="agentradio" value="notagent" />
                                No, I’m not a licensed agent
                            </label>
                        </div>
                        <div className="form-group">
                            <div className="row">
                                <div className="col-6 text-left primary-label">
                                    <Link to="/login" style={{ color: "#FF3D00 !important" }}>Sign in instead</Link>
                                </div>
                                {userTypes.includes(usertype) ?
                                    <div className="col-6 text-right">
                                        <Button variant="contained" color="primary" onClick={handleSubmit}>Next</Button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}