
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { SHOW_NOTIFICATION } from '../store/types';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
            marginTop: 80,
    },
}));

export default function Toaster() {
    const {message, type} = useSelector(state => state.ui.notification)
    const dispatch = useDispatch()

    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClose = (event, reason) => {
        dispatch({type:SHOW_NOTIFICATION,payload:{ type:'',message:''}})

        if (reason === 'clickaway') {
            return;
        }
      //  dispatch({type:SHOW_NOTIFICATION,payload:{ type:'',message:''}})
       // setOpen(false);
    };

    return (
        <div>
            {type && message ?
                <Snackbar
                classes={{root:classes.root}}
                    open={true}
                    autoHideDuration={3000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert onClose={handleClose} severity={type}>
                        {message}
                </Alert>
                </Snackbar>
                : null}
        </div>
    );
}