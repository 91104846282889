import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Drawer, Grid, IconButton } from '@material-ui/core';
import logo from '../images/drawerLogo.png';
import CloseIcon from '@material-ui/icons/Close';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MessageIcon from '@material-ui/icons/Message';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory,Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from '../store/actions/uiactions';
import { logoutUser } from '../store/actions/useractions';

const useStyles = makeStyles({
  list: {
    width: "100vw",
    padding: "30px",
    position: 'relative',
    height: '100vh'
  },
  fullList: {
    width: 'auto',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'space-between',
    marginBottom: "48px"
  },
  linkText: {
    fontSize: "20px",
    fontWeight: 500
  }
});

export default function AppDrawer({ open }) {
  const classes = useStyles();
  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  const history = useHistory()
  
  const handleLink = (link) => {
    toggleDrawer()
    history.push(link)
  }
  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
    // onClick={() => toggleDrawer()}
    //onKeyDown={() => toggleDrawer()}
    >
      <div
        className={classes.top}
        role="presentation"
      >
        <Link to="/"><img src={logo} onClick={toggleDrawer} /></Link>
        <IconButton onClick={() => toggleDrawer()}><CloseIcon fontSize='large' /></IconButton>
      </div>
      <List>
        <ListItem button>
          <ListItemIcon><EditRoundedIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => handleLink('/dashboardForm')} primary={'Create Appointment'} />
        </ListItem>
        <ListItem button>
          <ListItemIcon><SearchIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => handleLink('/searchApt')} primary={'Search'} />
        </ListItem>
        <ListItem button>
          <ListItemIcon><CalendarTodayIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => handleLink('/agentAppointment/all')} primary={'My Appointments'} />
        </ListItem>
        {/* <ListItem button>
          <ListItemIcon><MessageIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => handleLink('/messages')} primary={'Messages'} />
        </ListItem> */}
        <ListItem button>
          <ListItemIcon><AccountCircleIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => handleLink('/myAccount')} primary={'My Account'} />
        </ListItem>
      </List>
      <List style={{ bottom: "10px", position: 'absolute', width: "100vw" }}>
        <ListItem button>
          <ListItemIcon><ExitToAppIcon fontSize='medium' /></ListItemIcon>
          <ListItemText classes={{ primary: classes.linkText }} onClick={() => { logoutUser() }} primary={'Sign Out'} />
        </ListItem>
      </List>
    </div>
  );
  const drawerContent = () => (
    <>
      <div
        className={classes.list}
        role="presentation"
        onClick={() => toggleDrawer()}
        onKeyDown={() => toggleDrawer()}
      >

      </div>
    </>
  )

  return (
    <div>
      <React.Fragment key={'left'}>
        {/* <Button onClick={toggleDrawer('left', true)}>{'Left'}</Button> */}
        <Drawer
          // anchor={'left'}
          open={open}
          //  onClose={() => toggleDrawer()}
          onOpen={() => toggleDrawer()}
        >
          {list('left')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
