import React, { useMemo } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import ProjectRoutes from '../Routes';
import lazyComponent from './lazyComponent';
import { useSelector } from 'react-redux';
import HomeComponent from '../containers/Pages/Home';

const Router = () => {
    const state = useSelector(state => state)
    console.log(state)
    const routeComponents = useMemo(() => {
        return ProjectRoutes.map((route, index) => {
            // console.log("././route", route)
            if (route.isPrivate) {
                // console.log("rendering Private route", route.path);
                return (
                    <PrivateRoute
                        key={"router-" + index}
                        path={route.path}
                        exact
                        component={lazyComponent(route.component, route.componentPath)}
                    />
                );
            } else {
                return (
                    <Route
                        key={"router-" + index}
                        path={route.path}
                        component={lazyComponent(route.component, route.componentPath)}
                    />
                );
            }
        });
    }, []);
    return (
        <Switch>
            {/* <Route path="/" exact render={() => <Redirect to="/home" />} /> */}
            {routeComponents}
            <Route path="/" render={() => <HomeComponent />} />
        </Switch>
    );
};

export default withRouter(Router);
