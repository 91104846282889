import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/styles.css';
import logo from '../../images/RTH_LOGO.png';
import vantage_logo from '../../images/vantage_logo_new.png';
import { useSelector } from 'react-redux';
import { Button, Hidden, IconButton, Typography, makeStyles } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import AppDrawerNoAuth from '../DrawerNoAuth';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    }
}))
export default function AppbarNoAuth() {
    const { auth } = useSelector(state => state.auth);
    const classes = useStyles()
    const [drawerOpen, setDrawerOpen] = useState(false)
    return (
        <>
            <header id="header" className="fixed-top" style={{ position: "sticky" }} >
                <div className="container-fluid">
                    <nav className="navbar-custom " style={{ display: 'flex', marginRight: 'auto', padding: '0px' }}>
                        <IconButton edge="start"
                            onClick={() => { setDrawerOpen(!drawerOpen) }} //setDrawerOpen(!drawerOpen)} 
                            className={classes.menuButton}
                            color="inherit" aria-label="menu">
                            <MenuIcon fontSize="large" />
                        </IconButton>
                        <div className="logo navbar-brand mr-4" style={{ marginLeft: 10, width: "auto" }}>
                            <a href="/">
                                <img src={logo} alt="Realtour Logo" className="img-fluid" style={{ maxHeight: 28 }} />
                            </a>
                            {/* <a href="/">
                                <Typography component={'span'} variant='caption' style={{ color: '#000' }}>Powered By </Typography>
                                <img src={vantage_logo} alt="Realtour Logo" className="img-fluid" style={{ maxHeight: 32 }} />
                            </a> */}
                        </div>
                        <div className="mob-btn ml-auto ">
                            <Hidden mdUp>
                                <Link to="/login" className='text-black'>
                                    <Button color="primary" style={{ fontWeight: 600, width: 116 }}>Sign&nbsp;In</Button>
                                </Link>
                            </Hidden>
                        </div>
                        <div className="main-nav d-none d-lg-block">
                            <a className="mobile-logo" href="/"><img src={logo} alt="Realtour Logo" width="28px" height="31" className="img-fluid" /></a>
                            <div className="nav-custom">
                                <ul>
                                    <li className="nav-item pl-4"><a style={{ fontWeight: 400 }} href="/#video">How it works</a></li>

                                    <li className="nav-item pl-4"><a style={{ fontWeight: 400 }} href="/#contactform">Contact us</a></li>
                                </ul>
                                <ul className="nav-btn  ml-auto mb-auto">
                                    <li className=""><Link to="/user-type"><button type="button" className="btn btn-dark sign-up" style={{ width: '116px', border: 'none' }}>Sign up</button></Link></li>
                                    <li className=""><Link to="/login"><button type="button" className="btn btn-outline-dark sign-in m-l-15" style={{
                                        width: '116px', border: "1px solid #cec9c9",
                                        color: "#ff3d00", onMouseOver: "color : 'white'"
                                    }}>Sign in</button></Link></li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <AppDrawerNoAuth open={drawerOpen} toggleDrawer={() => setDrawerOpen(!drawerOpen)} />
        </>
    )
}

