import { Backdrop, CircularProgress } from "@material-ui/core";
import { useSelector } from 'react-redux';

export default function MainLoading(){
    const loading = useSelector(state => state.ui.loading)

    return (
        <Backdrop style={{ zIndex: 2500, color: "#fff" }} open={loading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}