export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const TOGGLE_DRAWER = "TOGGLE_DRAWER";

export const SIGNUP_USER_TYPE = "SIGNUP_USER_TYPE";
export const SIGNUP_STEP1_SAVE = "SINGUP_STEP1_SAVE";
export const SIGNUP_STEP2_SAVE = "SINGUP_STEP2_SAVE";
export const SIGNUP_STEP3_SAVE = "SINGUP_STEP3_SAVE";

export const SET_USER = "SET_USER"