import { START_LOADING, STOP_LOADING, SHOW_NOTIFICATION, TOGGLE_DRAWER } from "../types";

const INITIAL_STATE = {
    loading: false,
    drawer: false,
    notification: {
        message: "",
        type: ""
    }
}

export const uireducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, loading: true }

        case SHOW_NOTIFICATION:
            return { ...state, notification: action.payload }
        case STOP_LOADING:
            return { ...state, loading: false }
        case TOGGLE_DRAWER:
            return { ...state, drawer: !state.drawer }
        default:
            return state;
    }
}