import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useSelector } from 'react-redux';

export const AppContext = createContext({
    authenticated: true,
    isLoading: false,
    setIsLoading: () => { },
    setAuthenticated: () => { },
    isMobile: false,
    isDesktop: true,
    isTablet: false,
    width: window.innerWidth,
    device: "mobile",
    connection: null,
});

export const AppContextConsumer = AppContext.Consumer;
function notifyMe(msgData, user) {
    if(msgData.senderId=== user.id)
    return
    let icon = 'https://www.realtour.io/icons/favicon-32x32.png'
    let body = msgData.message
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      let notification = new Notification("New Message", { body: body, icon: icon,  });
      notification.onclick = ()=> {
        window.parent.focus()
        window.location.href = `/messages/${msgData.conversationId}`
      }
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
            let notification = new Notification("New Message", { body: body, icon: icon });
            notification.onclick =()=> {
                window.parent.focus()
                window.location.href = `/messages/${msgData.conversationId}`
              }            // …
        }
      });
    }
}
  
export const useApp = () => useContext(AppContext)

export const AppContextProvider = (props) => {
    const [device, setDevice] = useState("desktop")
    const [isLoading, setIsLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(true);
    const { user, auth, signalRToken } = useSelector(state => state.auth);
    const [connection, setCOnnection] = useState(null)

    const isDesktop = device == 'desktop'
    const isMobile = device == 'mobile'
    const isTablet = device == 'tablet'
    /**
     * Initial Context
     */
    const context = {
        isLoading,
        setIsLoading,
        authenticated,
        setAuthenticated,
        isMobile: isMobile,
        isDesktop: isDesktop,
        isTablet: isTablet,
        device: device,
        connection: connection
    };

    const resize = () => {
        // console.log("Resize:", window.innerWidth)
        if (window.innerWidth < 567 && !isMobile) {
            setDevice("mobile")
        }
        if (window.innerWidth >= 567 && window.innerWidth < 992 && !isTablet) {
            setDevice("tablet")
            console.log("Resize:", window.innerWidth)
        }
        if (window.innerWidth >= 992 && !isDesktop) {
            setDevice("desktop")
        }
    }

    useLayoutEffect(() => {
        window.addEventListener('resize', resize)
        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [device])

    useLayoutEffect(() => {
        resize()
    }, [])

    useEffect(() => {
        if (auth) {
            let connect = new HubConnectionBuilder()
                .withUrl(`${[process.env.REACT_APP_REALTOUR_API_URL]}/api?userId=${user.id}`, {
                    accessTokenFactory: () => {
                        return signalRToken
                    }
                })
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Information)
                .build()
            // connect.Headers.Add("userId", "maria");

            connect.on("start", () => {
                console.log("Realtour Messaging ready");
            })
            try {
                connect.start()
                    .then(() => {
                        console.log("connection", connect)
                        setCOnnection(connect);
                    })
                    .catch(err => {
                        console.error("Failed to connect to realtour messaging", err)
                    })
                connect.on("newMessage", (data) => {
                    notifyMe(JSON.parse(data), user)
                })
            } catch (error) {
                console.log(error)
            }
        }
    }, [auth])
    return (
        <AppContext.Provider value={context}>
            {props.children}
        </AppContext.Provider>
    );
};