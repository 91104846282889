import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppContext } from './../Contexts/AppContext';
import { useSelector } from 'react-redux';

const PrivateRoute = (props) => {
    const { authenticated } = useContext(AppContext);
    const { auth } = useSelector(state => state.auth)
    const { component, ...others } = props;
    const Component = component;

    return !auth ? (
        <Route
            {...others}
            render={(props) => (
                <Redirect
                    to={{
                        pathname: '/login',
                        search: `?redirect=${props.location.pathname}&query=${props.location.search}`,
                        state: { from: props.location },
                    }}
                />
            )}
        />
    ) : (
            <Route {...others} render={(props) => <Component {...props} />} />
        );
};

export default PrivateRoute;
