import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/styles.css';
import logo from '../../images/RTH_LOGO.png';
import { Avatar, makeStyles, AppBar, Menu, IconButton, Typography, Toolbar, MenuItem, Hidden, Badge } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useSelector, useDispatch } from 'react-redux';
import AppDrawer from '../Drawer';
import { toggleDrawer } from '../../store/actions/uiactions';
import { logoutUser } from '../../store/actions/useractions';
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        background: "white",
        position: "sticky"
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: "none"
        }
    },
    title: {
        flexGrow: 1,
    },
    avatar: {
        background: theme.palette.primary.main,
    }
}))
export default function AppbarInner() {
    const classes = useStyles()
    const { auth, user } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const initial = user?.firstName ? user.firstName[0] : ''
    const ProfilePicture = user ? user.ProfilePicture : null
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    /// const [drawerOpen, setDrawerOpen] = useState(false)
    const drawerOpen = useSelector(state => state.ui.drawer)
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <AppDrawer open={drawerOpen}
            // toggleDrawer={() => setDrawerOpen(!drawerOpen)}
            />

            <div className={classes.root}>
                <AppBar position="sticky" color="white" elevation={0} style={{ boxShadow: "0px 1px 0px 0px rgb(0 0 0 / 12%)" }}>
                    <Toolbar>
                        <IconButton edge="start"
                            onClick={() => toggleDrawer()} //setDrawerOpen(!drawerOpen)} 
                            className={classes.menuButton}
                            color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                        <a href="/" className="navbar-brand"> <img src={logo} height="28" alt="" />
                        </a>
                        <Typography variant="h6" className={classes.title}>

                        </Typography>
                        {auth && (
                            <Hidden smDown>
                                <div>
                                    <IconButton>
                                        <Badge
                                            color='primary'
                                            badgeContent={2}
                                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                                            <NotificationsNoneRoundedIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <Avatar src={ProfilePicture} className={classes.avatar}>{initial}</Avatar>
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        getContentAnchorEl={null}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={(e) => { history.push('/') }}>Home</MenuItem>
                                        <MenuItem onClick={(e) => { history.push('/myAccount') }}>My account</MenuItem>
                                        <MenuItem onClick={logoutUser}>Logout</MenuItem>
                                    </Menu>
                                </div>
                            </Hidden>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        </>
    )
}